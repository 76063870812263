import React from "react";
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import { NavLink } from "react-router-dom";
import GlobalVar from "../GlobalVar";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import InstagramIcon from '@mui/icons-material/Instagram';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/styles.css";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

let itemData = [];
let itemDataLB = [];

export default function TentangKami(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tablet = useMediaQuery(theme.breakpoints.down('sm')); 

  const [indexImg, SetIndexImg] = React.useState(0);
  const [openLB, SetOpenLB] = React.useState(false);

  const [openLoading, setOpenLoading] = React.useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };
  const handleToggleLoading = () => {
    setOpenLoading(!openLoading);
  };
  
  // ====================== GET SERTIFIKAT ======================
    const [arraySertifikat, setArraySertifikat] = React.useState([]);
  // ====================== END - GET SERTIFIKAT ======================

  // ====================== GET TEAM ======================
    const [arrayTeam, setArrayTeam] = React.useState([]);
    // ====================== END - GET TEAM ======================

  const imgClick = (i) => {
    SetIndexImg(i);
    setTimeout(() => {
        SetOpenLB(true);
    }, 200);

  }


  const goToInstagram = (val) => {
    window.open(val, '_blank', 'noopener,noreferrer'); 
  }

  React.useEffect(() => {   
    handleToggleLoading();

    axios.get(GlobalVar.server+'GetSertifikat.php').then(function(response){

      let res = JSON.stringify(response.data);
      let resP = JSON.parse(res);
      setArraySertifikat(resP);
      
      itemData = [];
      itemDataLB = [];

      for(let y=0; y < resP.length; y++){
        itemData.push(
          {
            index: y,
            judul : resP[y].sertifikat_judul,
            img: resP[y].sertifikat_foto
          }
        );

        itemDataLB.push(
          {
            src: `data:image/*;base64, ${resP[y].sertifikat_foto}`
          }
        );

      }

      axios.get(GlobalVar.server+'GetTeam.php').then(function(response){

        let res = JSON.stringify(response.data);
        let resP = JSON.parse(res);
        setArrayTeam(resP);      
        handleCloseLoading();  
      });

    });
    
  }, []);


  return (
    <div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
        // onClick={handleCloseLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <div role="presentation" onClick={handleClick} className="navBreadcumb">
        <div className="titleBreadcumb">
          <Typography variant='h5'>Tentang Kami</Typography>
        </div>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Typography variant='body2'><NavLink to='../'>Beranda</NavLink></Typography>
          <Typography variant='body2'>Tentang Kami</Typography>
        </Breadcrumbs>
      </div>

      <Container style={{marginTop: mobile ? -100 : -150}}>
        <Grid container 
        spacing={mobile ? 4 : 10} px={mobile ? 2 : 0} my={4}>
            <Grid item xs={12}>
                <CardMedia
                    component="img"
                    height={mobile ? 240 : 440}
                    // image={`data:image/png;base64, ${props.fotoPria}`}
                    image={`data:image/*;base64,${props.info_foto_contoh}`}
                    className="img-smallRadius"
                    alt="gambar_cover"
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant={mobile ? "h5" : "h4"} className="font-weight" >{props.info_nama_perusahaan}</Typography>
                <Typography variant={mobile ? "body1" : "h6"} className='justify-text font-thin' ><i>{props.info_slogan}</i></Typography>
                <Typography variant={mobile ? "body1" : "h6"} mt={2} mb={2} className='justify-text font-thin' >{props.info_deskripsi_singkat}</Typography>
                <Divider />

                <Typography variant={mobile ? "h5" : "h4"} mt={2} gutterBottom className="font-weight listLeft" >Visi.</Typography>
                <Typography variant={mobile ? "body1" : "h6"} mt={2} mb={2} className='justify-text font-thin' >{props.info_visi}</Typography>
                <Divider />

                <Typography variant={mobile ? "h5" : "h4"} mt={2} gutterBottom className="font-weight listLeft" >Misi.</Typography>
                <Typography variant={mobile ? "body1" : "h6"} mt={2} mb={2} className='justify-text font-thin' >{props.info_misi}</Typography>
                <Divider />

                <Typography variant={mobile ? "h5" : "h4"} my={2} gutterBottom className="font-weight listLeft" >Sertifikat.</Typography>
                <Grid container 
                spacing={2}>
                  <ImageList variant="" cols={mobile ? 2  : tablet ? 3 : 6}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.index} onClick={() => imgClick(item.index)} style={{cursor: 'pointer'}}>
                            <img
                              src={`data:image/*;base64, ${item.img}`}
                              alt={item.judul}
                              loading="lazy"
                              alt={item.judul}
                            />
                        </ImageListItem>
                    ))}                  
                  </ImageList>

                </Grid>

                <Divider />

                <Typography variant={mobile ? "h5" : "h4"} my={2} gutterBottom className="font-weight listLeft" >Tim Kami.</Typography>
                {/* <Typography variant={mobile ? "body1" : "h6"} mt={2} mb={2} className='justify-text font-thin' >{props.info_visi}</Typography> */}
                
                <Grid container 
                spacing={mobile ? 4 : 10} px={mobile ? 2 : 0} my={4}>
                  {arrayTeam.map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.team_id}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="220"
                          image={`data:image/*;base64,${item.team_foto}`}
                          alt={item.team_nama}
                        />
                        <CardContent>
                          <Typography gutterBottom variant={mobile ? "body1" : "h6"} component="div" className="font-weight">
                            {item.team_nama}
                          </Typography>
                          <Typography  variant={mobile ? "body2" : "body1"}>
                          {item.team_posisi}
                          </Typography>
                        </CardContent>
                        {
                          item.team_instagram ?
                          <CardActions>
                              <Chip
                                icon={<InstagramIcon />}
                                component="a"
                                // onClick={goToInstagram(item.team_instagram)}
                                href={item.team_instagram}
                                target="_blank"
                                label={item.team_instagram_name}
                                variant="outlined"
                                clickable
                              />
                          </CardActions>
                          :
                          <CardActions>
                              <Chip
                                icon={<InstagramIcon />}
                                label="-"
                                variant="outlined"
                              />
                          </CardActions>
                        }
                        
                      </Card>
                    </Grid>
                  ))} 
                </Grid>

                <Divider />


            </Grid>

        </Grid>

      </Container>

      {
          openLB &&

          <Lightbox
              open={openLB}
              index={indexImg}
              close={() => { SetOpenLB(false); SetIndexImg(0); }}
              slides={itemDataLB}
              plugins={[Fullscreen, Slideshow,Zoom]}
              styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" } }}
          />
              // <Lightbox images={itemDataLB} startIndex={indexImg} onClose={() => { SetOpenLB(false); SetIndexImg(0); }} />
      }

    </div>
  );
}
