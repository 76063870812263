import React from "react";
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TextField from '@mui/material/TextField';
import { NavLink } from "react-router-dom";
import GlobalVar from "../../GlobalVar";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BusinessIcon from '@mui/icons-material/Business';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function Informasi(props) {

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [info_nama_perusahaan, setInfo_nama_perusahaan] = React.useState('');
  const [info_deskripsi_singkat, setInfo_deskripsi_singkat] = React.useState('');
  const [info_slogan, setInfo_slogan] = React.useState('');
  const [info_visi, setInfo_visi] = React.useState('');
  const [info_misi, setInfo_misi] = React.useState('');
  const [info_alamat, setInfo_alamat] = React.useState('');
  const [info_head_office, setInfo_head_office] = React.useState('');
  const [info_telp, setInfo_telp] = React.useState('');
  const [info_whatsapp, setInfo_whatsapp] = React.useState('');
  const [info_email, setInfo_email] = React.useState('');
  const [info_foto_contoh, setInfo_foto_contoh] = React.useState(null);

    const handleChangeNama = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_nama_perusahaan(event.target.value);
    };
    const handleChangeDeskripsi = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_deskripsi_singkat(event.target.value);
    };
    const handleChangeSlogan = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_slogan(event.target.value);
    };
    const handleChangeVisi = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_visi(event.target.value);
    };
    const handleChangeMisi = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_misi(event.target.value);
    };
    const handleChangeAlamat = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_alamat(event.target.value);
    };
    const handleChangeHO = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_head_office(event.target.value);
    };
    const handleChangeTelp = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_telp(event.target.value);
    };
    const handleChangeWA = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_whatsapp(event.target.value);
    };
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInfo_email(event.target.value);
    };
    
    const [mainState, setMainState] = React.useState('initial');
    const [imageUploaded, setImageUploaded] = React.useState(0);
    const handleUploadClick = event => {
        var file = event.target.files[0];
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function(e) {
            setInfo_foto_contoh([reader.result])
        }.bind(this);

        setMainState("uploaded")
        setInfo_foto_contoh(URL.createObjectURL(event.target.files[0]))
        setImageUploaded(1)
    };
    
  const saveInformasi = () => {
    const dataUpdate = {
        info_nama_perusahaan,
        info_deskripsi_singkat,
        info_slogan,
        info_visi,
        info_misi,
        info_alamat,
        info_head_office,
        info_telp,
        info_whatsapp,
        info_email,
        info_foto_contoh
    }

    axios.post(GlobalVar.server+'PostInformasi.php', dataUpdate).then(function(response){
        let res = JSON.stringify(response.data);
        let resP = JSON.parse(res);

        if(resP.statusCode==200){
            alert('Informasi berhasil diupdate.');
        }
        else if(resP.statusCode==500){
            alert('Gagal update data ! Silahkan dicoba kembali.');
        }
    });
  }

  React.useEffect(() => { 
    setInfo_nama_perusahaan(props.info_nama_perusahaan);
    setInfo_deskripsi_singkat(props.info_deskripsi_singkat);
    setInfo_slogan(props.info_slogan);
    setInfo_visi(props.info_visi);
    setInfo_misi(props.info_misi);
    setInfo_alamat(props.info_alamat);
    setInfo_head_office(props.info_head_office);
    setInfo_telp(props.info_telp);
    setInfo_whatsapp(props.info_whatsapp);
    setInfo_email(props.info_email);
    setInfo_foto_contoh(`data:image/*;base64,${props.info_foto_contoh}`);
  }, []);

  return (
    <div className="containerVHC">
        
        <Container>
        <Grid container 
        spacing={mobile ? 4 : 10} >

            <Grid item xs={12}>

                <TextField
                  id="outlined-multiline-flexible"
                  label="Nama Anda / Perusahaan"
                  value={info_nama_perusahaan}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeNama}
                />       

                <TextField
                  id="outlined-multiline-flexible"
                  label="Email"
                  value={info_email}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeEmail}
                />           

                <TextField
                  id="outlined-multiline-flexible"
                  label="Slogan"
                  value={info_slogan}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeSlogan}
                />            

                <TextField
                  id="outlined-multiline-flexible"
                  label="Visi"
                  value={info_visi}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeVisi}
                />          

                <TextField
                  id="outlined-multiline-flexible"
                  label="Misi"
                  value={info_misi}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeMisi}
                />          

                <TextField
                  id="outlined-multiline-flexible"
                  label="Telepon"
                  value={info_telp}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeTelp}
                />      

                <TextField
                  id="outlined-multiline-flexible"
                  label="No. WhatsApp"
                  value={info_whatsapp}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeWA}
                />              

                <TextField
                  id="outlined-multiline-flexible"
                  label="Alamat"
                  value={info_alamat}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeAlamat}
                />      

                <TextField
                  id="outlined-multiline-flexible"
                  label="Head Office"
                  value={info_head_office}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeHO}
                />       

                <TextField
                  id="outlined-multiline-flexible"
                  label="Deskripsi"
                  multiline
                  rows={10}
                  value={info_deskripsi_singkat}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeDeskripsi}
                />  

                <Typography variant={mobile ? "h6" : "h5"} mt={2} >Foto Deskripsi</Typography>
                <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                    style={{margin: '20px 0px'}}
                />
                <img src={info_foto_contoh} width={mobile ? 200 : 400} />


                <Button variant="outlined" className="btnPrimary" style={{marginTop: 40, width: '100%'}} onClick={saveInformasi}>Simpan</Button>

            </Grid>

        </Grid>

      </Container>

    </div>
  );
}
