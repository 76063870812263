import React from "react";
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TextField from '@mui/material/TextField';
import { NavLink } from "react-router-dom";
import GlobalVar from "../../GlobalVar";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BusinessIcon from '@mui/icons-material/Business';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Informasi from "./Informasi";
import Layanan from "./Layanan";
import Proyek from "./Proyek";
import Team from "./Team";
import Sertifikat from "./Sertifikat";

export default function Main(props) {

  const [valueTab, setValueTab] = React.useState('1');
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [info_nama_perusahaan, setInfo_nama_perusahaan] = React.useState('');
  const [info_deskripsi_singkat, setInfo_deskripsi_singkat] = React.useState('');
  const [info_slogan, setInfo_slogan] = React.useState('');
  const [info_visi, setInfo_visi] = React.useState('');
  const [info_misi, setInfo_misi] = React.useState('');
  const [info_alamat, setInfo_alamat] = React.useState('');
  const [info_head_office, setInfo_head_office] = React.useState('');
  const [info_telp, setInfo_telp] = React.useState('');
  const [info_whatsapp, setInfo_whatsapp] = React.useState('');
  const [info_email, setInfo_email] = React.useState('');
  const [info_foto_contoh, setInfo_foto_contoh] = React.useState('');

  React.useEffect(() => { 
    
  }, []);

  return (
    <div className="containerVHC">
        <Box sx={{ width: '100%' }}>
            <TabContext value={valueTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="lab API tabs example" centered>
                    <Tab label="Informasi" value="1" />
                    <Tab label="Sertifikat" value="2" />
                    <Tab label="Team" value="3" />
                    <Tab label="Layanan" value="4" />
                    <Tab label="Proyek" value="5" />
                </TabList>
                </Box>
                <TabPanel value="1"><Informasi  info_nama_perusahaan={props.info_nama_perusahaan} info_deskripsi_singkat={props.info_deskripsi_singkat} 
                    info_slogan={props.info_slogan} info_visi={props.info_visi} info_misi={props.info_misi} info_foto_contoh={props.info_foto_contoh} 
                    info_alamat={props.info_alamat} info_telp={props.info_telp} info_head_office={props.info_head_office} info_email={props.info_email} 
                    info_whatsapp={props.info_whatsapp} /></TabPanel>
                <TabPanel value="2"><Sertifikat /></TabPanel>
                <TabPanel value="3"><Team /></TabPanel>
                <TabPanel value="4"><Layanan /></TabPanel>
                <TabPanel value="5"><Proyek /></TabPanel>
            </TabContext>
        </Box>

    </div>
  );
}
