/**
 * @author Kevin.Bagasdwika
 * 
 */

 class GlobalVar {
    //\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
            
    static server = 'https://dakamegaperkasa.com/api_daka/';
    static vid = 'https://dakamegaperkasa.com/video';
   //  static server = 'http://ptdaka.000webhostapp.com/api_daka/';
   //  static vid = 'http://ptdaka.000webhostapp.com/video';
   //  static server = 'http://localhost/api_daka/';
   //  static vid = 'http://localhost/video';
        
    //\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
    }
    
    export default GlobalVar;
    
    