import React from "react";
import { useTheme, useMediaQuery } from '@material-ui/core';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import TextField from '@mui/material/TextField';
import { NavLink } from "react-router-dom";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BusinessIcon from '@mui/icons-material/Business';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function Kontak(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tablet = useMediaQuery(theme.breakpoints.down('sm')); 

  const [nama, setNama] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [pesan, setPesan] = React.useState('');

  const handleChangeNama = (event) => {
    setNama(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePesan = (event) => {
    setPesan(event.target.value);
  };

  const kirimPesan = (val) => {
    window.open('mailto:'+props.info_email+'?subject=[ASK] '+encodeURIComponent(nama)+'&body='+encodeURIComponent(pesan));
  }

  return (
    <div>
      <div role="presentation" onClick={handleClick} className="navBreadcumb">
        <div className="titleBreadcumb">
          <Typography variant='h5'>Hubungi Kami</Typography>
        </div>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Typography variant='body2'><NavLink to='../'>Beranda</NavLink></Typography>
          <Typography variant='body2'>Hubungi Kami</Typography>
        </Breadcrumbs>
      </div>

      <Container>
        <Grid container 
        spacing={mobile ? 4 : 10} px={mobile ? 2 : 0} my={4}>

            <Grid item xs={12} md={6}>
                <Typography variant={mobile ? "body1" : "h6"} mb={2} className='justify-text font-thin listLeft' >
                Kami ingin mendengar dan ikut serta dalam ide spektakuler Anda untuk membangun lingkungan yang inspiratif. Silahkan isi form kontak sedetail mungkin agar kami dapat menghubungi dan menjawab kebutuhan Anda.
                </Typography>
                <Divider />

                <Typography variant={mobile ? "h5" : "h4"} mt={2} gutterBottom className="" ><BusinessIcon sx={{fontSize : mobile ? 28 : 40}}/> Alamat</Typography>
                <Typography variant={mobile ? "body1" : "h6"} mt={2} ml={mobile ? 4 : 6} className='justify-text' >Manufaktur</Typography>
                <Typography variant={mobile ? "body1" : "h6"} mb={2} ml={mobile ? 4 : 6} className='justify-text font-thin' >{props.info_alamat}</Typography>

                <Typography variant={mobile ? "body1" : "h6"} mt={2} ml={mobile ? 4 : 6} className='justify-text' >Head Office</Typography>
                <Typography variant={mobile ? "body1" : "h6"} mb={2} ml={mobile ? 4 : 6} className='justify-text font-thin' >{props.info_head_office}</Typography>
                <Divider />

                <Typography variant={mobile ? "h5" : "h4"} mt={2} gutterBottom className="" ><CallIcon sx={{fontSize : mobile ? 28 : 40}}/> Telepon</Typography>   
                <Typography variant={mobile ? "body1" : "h6"} mt={2} mb={2} ml={mobile ? 4 : 6} className='justify-text font-thin' >{props.info_telp}</Typography>
                <Divider />           

                <Typography variant={mobile ? "h5" : "h4"} mt={2} gutterBottom className="" ><MailOutlineIcon sx={{fontSize : mobile ? 28 : 40}}/> Email</Typography>   
                <Typography variant={mobile ? "body1" : "h6"} mt={2} mb={2} ml={mobile ? 4 : 6} className='justify-text font-thin' >{props.info_email}</Typography>
                <Divider />      

            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant={mobile ? "h5" : "h4"} mb={2} className='justify-text listLeft' >Informasi</Typography>
                <Divider />

                <TextField
                  id="outlined-multiline-flexible"
                  label="Nama Anda / Perusahaan"
                  value={nama}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeNama}
                />       

                <TextField
                  id="outlined-multiline-flexible"
                  label="Email"
                  value={email}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeEmail}
                />           

                <TextField
                  id="outlined-multiline-flexible"
                  label="Pesan"
                  value={pesan}
                  multiline
                  rows={10}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangePesan}
                />         
                <center style={{marginTop: 40}}><Button variant="outlined" className="btnPrimary" onClick={kirimPesan}>Kirim Pesan</Button></center>

            </Grid>

        </Grid>

      </Container>

    </div>
  );
}
