import * as React from 'react';
import { NavLink } from "react-router-dom";
import { useTheme, useMediaQuery } from '@material-ui/core';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const drawerWidth = 240;

const navItems = [
    { name: 'Beranda', path: '' }, 
    { name: 'Tentang Kami', path: 'TentangKami' }, 
    { name: 'Layanan', path: 'Layanan' }, 
    { name: 'Proyek', path: 'Proyek' }, 
    { name: 'Hubungi Kami', path: 'Kontak' }
];

function Navbar() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
          <img src={require('../images/logo.png')} className="App-logo" alt="DAKA" />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.name} as={NavLink} to={item.path}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );


  const myRef = React.useRef();
  
  const [scrolled, setScrolled] = React.useState(false);
  const [y, setY] = React.useState();
  const [scrollPosition, setPosition] = React.useState(0);
    
  // This function calculate Y
  const getPosition = () => {
    const y = myRef.current.offsetTop;
    mobile ? setY(y-600) : setY(y-800);
  };

   // Get the position of the red box in the beginning
   React.useEffect(() => {
    getPosition();
  }, []);

  React.useEffect(() => {
    if (scrollPosition > 60) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
 }, [scrollPosition]);


  React.useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);


  return (
    <Box ref={myRef} sx={{ display: 'flex' }}>
      <AppBar component="nav" className={scrolled ? 'scrolled' : ''}>
        {
          !mobile &&
          <div className='navTop'>
            <center>
              <Typography variant={mobile ? "caption" : "caption"} >Telp: 021-22016304 | Email: info@dakamegaperkasa.com</Typography>   
            </center>
          </div>
        }
        <Container>
          <Toolbar>
            <Typography
              variant="h6"
              sx={{ flexGrow: mobile ? 0 : 1 }}
              as={NavLink} to=''
            >            
              <img src={require('../images/logo.png')} className="App-logo" alt="DAKA" />
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <Button key={item.name}>
                  <NavLink to={item.path}>{item.name}</NavLink>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

        <Box component="nav">
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>


    </Box>
  );
}

export default Navbar;