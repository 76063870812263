import React from "react";
import { useTheme, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { NavLink } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';
import EastIcon from '@mui/icons-material/East';

import GlobalVar from "../GlobalVar";

function  DialogISO1(props) {
  const { onClose, selectedValue, open } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);    
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {
        selectedValue &&
          <img src={selectedValue}  alt="DAKA Sertifikat" />
      }
    </Dialog>
  );
}

 DialogISO1.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function Beranda(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');

  const [openLoading, setOpenLoading] = React.useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };
  const handleToggleLoading = () => {
    setOpenLoading(!openLoading);
  };

  // ====================== GET SERTIFIKAT ======================
    const [arraySertifikat, setArraySertifikat] = React.useState([]);
  // ====================== END - GET SERTIFIKAT ======================

  // ====================== GET LAYANAN ======================
    const [arrayLayanan, setArrayLayanan] = React.useState([]);
    // ====================== END - GET LAYANAN ======================

  // ====================== GET PROYEK ======================
    const [arrayProyek, setArrayProyek] = React.useState([]);
    // ====================== END - GET PROYEK ======================

  const handleClickOpen = (val) => {
    setSelectedValue(val);
    setTimeout(() => {
      setOpen(true);
    }, 200);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  React.useEffect(() => {    
    handleToggleLoading();

    axios.get(GlobalVar.server+'GetSertifikat.php').then(function(response){
      let res = JSON.stringify(response.data);
      let resP = JSON.parse(res);
      setArraySertifikat(resP);

        axios.get(GlobalVar.server+'GetLayanan.php').then(function(response){
          let res = JSON.stringify(response.data);
          let resP = JSON.parse(res);
          setArrayLayanan(resP);


            axios.get(GlobalVar.server+'GetProyek.php').then(function(response){
              let res = JSON.stringify(response.data);
              let resP = JSON.parse(res);
              setArrayProyek(resP);
              handleCloseLoading();
            });
            
        });

    });
    
  }, []);
  return (
    <Box sx={{ width: '100%' }}>

    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
        // onClick={handleCloseLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


      <DialogISO1
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />

      <div className="sectionOverlayInside">
        <video className="videoBeranda" autoPlay loop muted>
          <source src={GlobalVar.vid+'_daka.mp4'} type="video/mp4" />
        </video>
        <div className="sectionOverlayOutside containerVE containerHC">
            <Typography variant={mobile ? "h5" : "h4"} className="font-weight" >{props.info_nama_perusahaan}</Typography>
            <Typography variant={mobile ? "body1" : "h6"} className="font-weight" mb={4} ><i>{props.info_slogan}</i></Typography>
        </div>
      </div>


      <Container>
        <Grid container 
        spacing={mobile ? 4 : 10} px={mobile ? 2 : 0} my={4}>
            <Grid item xs={12} md={6}>
                <CardMedia
                    component="img"
                    height={mobile ? 240 : 440}
                    // image={`data:image/png;base64, ${props.fotoPria}`}
                    image={`data:image/*;base64,${props.info_foto_contoh}`}
                    className="img-smallRadius"
                    alt=""
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant={mobile ? "h5" : "h4"} gutterBottom className="font-weight" >{props.info_nama_perusahaan}</Typography>
                <Divider />
                <Typography variant={mobile ? "body1" : "h6"} mt={2} className='justify-text font-thin' >{props.info_deskripsi_singkat.substring(0, 250)+' ...'}</Typography>

                <Grid container 
                spacing={2} mt={2}>
                    {arraySertifikat.slice(0, 3).map((item) => (
                        <Grid item xs={4} md={3}> <Chip label={item.sertifikat_judul} variant="outlined" size="medium" 
                        onClick={() => handleClickOpen(`data:image/*;base64,${item.sertifikat_foto}`)} /> </Grid>
                    ))}                  
                  <Grid item xs={12} sx={{mt: 3}}> <Button variant="outlined" className="btnPrimary" as={NavLink} to='/TentangKami'>Selengkapnya</Button> </Grid>
                </Grid>
            </Grid>

        </Grid>

      </Container>

      <div className="sectionBeranda-layanan">
        <div className="sectionOverlayInsideLayanan">
          <div className={mobile ? "sectionOverlayOutsideLayanan containerHC" : "sectionOverlayOutsideLayanan containerVHC" }>
            <center>
              <Typography variant={mobile ? "h5" : "h4"} mt={4} className="font-weight" >Layanan Kami.</Typography>
            </center>
            <Container>
              <Grid container spacing={mobile ? 4 : 10} px={mobile ? 2 : 0} mt={0} mb={4}>

                {arrayLayanan.slice(0, 3).map((item) => (
                    <Grid item xs={12} md={4}>
                      <center>
                        <Typography variant={mobile ? "h6" : "h5"} gutterBottom className="" >{item.layanan_judul}</Typography>
                        <Typography variant={mobile ? "body1" : "h6"} mt={2} className='font-thin' >{item.layanan_deskripsi.substring(0, 80)+' ...'}</Typography>
                      </center>
                    </Grid>
                ))}   

                <Grid item xs={12} my={4}> <center><Button variant="outlined" className="btnPrimary" as={NavLink} to='/Layanan'>Selengkapnya</Button></center> </Grid>
                  
              </Grid>

            </Container>
          </div>
        </div>
      </div>

      <div className="">
        <div className={mobile ? "containerHC" : "containerVHC" }>
          <center>
            <Typography variant={mobile ? "h5" : "h4"} mt={4} className="font-weight" >Proyek Kami.</Typography>
          </center>
          <Container>
            <Grid container spacing={mobile ? 2 : 4} px={mobile ? 2 : 0} mt={mobile ? 2 : 0} mb={4}>
                {arrayProyek.slice(0, 3).map((item) => (
                    <Grid item xs={12} md={4}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="240"
                          image={`data:image/*;base64,${item.project_gambar}`}
                          alt={item.project_judul}
                        />
                        <CardContent>
                          <Typography variant="h5" component="div">
                          {item.project_judul.substring(0, 22)+" ..."}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                          {/* {item.project_bulan},  */}
                          {item.project_tahun}
                          </Typography>
                          <Typography variant="body2" mt={2} color="text.secondary">
                            {item.project_deskripsi.substring(0, 70)+' ...'}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                ))}       

                <Grid item xs={12} my={4}> <center><Button variant="outlined" className="btnPrimary" as={NavLink} to='/Proyek'>Selengkapnya</Button></center> </Grid>
                
            </Grid>
          </Container>
        </div>
      </div>


    </Box>
  );
}
