import React from "react";
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Navbar from "./component/Navbar";
import Beranda from "./menus/Beranda";
import TentangKami from "./menus/TentangKami";
import Layanan from "./menus/Layanan";
import Proyek from "./menus/Proyek";
import Kontak from "./menus/Kontak";
import Footer from "./menus/Footer";

import Login from "./menus/admin/Login";

import GlobalVar from "./GlobalVar";
import './App.css';

// Tentang Kami
// Visi & Misi
// Gambar Klien2
// ada sejarah ? (kalau ada dibikin timeline)


// Layanan
// Produk

// Proyek2 yang sudah dan sedang berjalan (Lokasi, tema, Tanggal jika sudah selesai)

export default function App() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [admin, setAdmin] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [pathName, setPathName] = React.useState('');

  // ====================== GET INFORMATION ======================
    const [info_nama_perusahaan, setInfo_nama_perusahaan] = React.useState('');
    const [info_deskripsi_singkat, setInfo_deskripsi_singkat] = React.useState('');
    const [info_slogan, setInfo_slogan] = React.useState('');
    const [info_visi, setInfo_visi] = React.useState('');
    const [info_misi, setInfo_misi] = React.useState('');
    const [info_alamat, setInfo_alamat] = React.useState('');
    const [info_head_office, setInfo_head_office] = React.useState('');
    const [info_telp, setInfo_telp] = React.useState('');
    const [info_whatsapp, setInfo_whatsapp] = React.useState('');
    const [info_email, setInfo_email] = React.useState('');
    const [info_foto_contoh, setInfo_foto_contoh] = React.useState('');
  // ====================== END - GET INFORMATION ======================

  React.useEffect(() => { 
    let split = window.location.pathname.split('/')

    setPathName(split[1]);
    axios.get(GlobalVar.server+'GetInformation.php').then(function(response){
        let res = JSON.stringify(response.data);
        let resP = JSON.parse(res);
        
        setInfo_nama_perusahaan(resP[0].info_nama_perusahaan);
        setInfo_deskripsi_singkat(resP[0].info_deskripsi_singkat);
        setInfo_slogan(resP[0].info_slogan);
        setInfo_visi(resP[0].info_visi);
        setInfo_misi(resP[0].info_misi);
        setInfo_alamat(resP[0].info_alamat);
        setInfo_head_office(resP[0].info_head_office);
        setInfo_telp(resP[0].info_telp);
        setInfo_whatsapp(resP[0].info_whatsapp);
        setInfo_email(resP[0].info_email); 
        setInfo_foto_contoh(resP[0].info_foto_contoh); 
    });
    
  }, []);

  return (
    <div className="mainSection" >
      <Router>
        {pathName != 'DPanel' &&
          <Navbar info_telp={info_telp} info_email={info_email}/>
        }

          <Routes>
            <Route path="/DPanel" element={<Login info_nama_perusahaan={info_nama_perusahaan} info_deskripsi_singkat={info_deskripsi_singkat} 
            info_slogan={info_slogan} info_visi={info_visi} info_misi={info_misi} info_foto_contoh={info_foto_contoh} 
            info_alamat={info_alamat} info_telp={info_telp} info_head_office={info_head_office} info_email={info_email} info_whatsapp={info_whatsapp} />} />

            <Route path="/TentangKami" element={<TentangKami info_nama_perusahaan={info_nama_perusahaan} info_deskripsi_singkat={info_deskripsi_singkat} 
            info_slogan={info_slogan} info_visi={info_visi} info_misi={info_misi} info_foto_contoh={info_foto_contoh} />} />
            <Route path="/Layanan" element={<Layanan/>} />
            <Route path="/Proyek" element={<Proyek/>} />
            <Route path="/Kontak" element={<Kontak info_alamat={info_alamat} info_telp={info_telp} info_head_office={info_head_office} info_email={info_email} />} />
            <Route path="/" element={<Beranda info_nama_perusahaan={info_nama_perusahaan} info_deskripsi_singkat={info_deskripsi_singkat} info_alamat={info_alamat} info_telp={info_telp} 
                info_head_office={info_head_office} info_email={info_email} info_slogan={info_slogan} info_foto_contoh={info_foto_contoh} />} />
          </Routes>
      </Router>
          
      {pathName != 'DPanel' &&
        <Footer info_nama_perusahaan={info_nama_perusahaan} info_deskripsi_singkat={info_deskripsi_singkat} info_alamat={info_alamat} info_telp={info_telp} 
        info_head_office={info_head_office} info_email={info_email} info_whatsapp={info_whatsapp}/>
      }
    </div>
  );
}
