import React from "react";
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import GlobalVar from "../../GlobalVar";
import Main from "./Main";

export default function Login(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const handleChangeUserName = (event) => {
    setUserName(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };  

  const login = () => {
    
    axios.get(GlobalVar.server+'Login.php?username='+userName+'&pass='+password).then(function(response){
        let res = JSON.stringify(response.data);
        let resP = JSON.parse(res);
        if(resP.length > 0) {
          setIsLoggedIn(true);
        } else {
            alert('Username / password salah !');
            setPassword('');
            setIsLoggedIn(false);
        }
    });    

  }

  React.useEffect(() => { 

  }, []);

  return (
    <div className="containerVHC">
      {
        isLoggedIn ?
          <Main info_nama_perusahaan={props.info_nama_perusahaan} info_deskripsi_singkat={props.info_deskripsi_singkat} 
          info_slogan={props.info_slogan} info_visi={props.info_visi} info_misi={props.info_misi} info_foto_contoh={props.info_foto_contoh} 
          info_alamat={props.info_alamat} info_telp={props.info_telp} info_head_office={props.info_head_office} info_email={props.info_email} 
          info_whatsapp={props.info_whatsapp} />
        :
          <Card sx={{ maxWidth: mobile ? '90%' : 460, py: 4 }}>
              <CardContent>
                  <Typography variant={mobile ? "h5" : "h4"} mb={2} className='justify-text listLeft' >Login Admin</Typography>
                  <Divider />

                  <TextField
                  id="outlined-multiline-flexible"
                  label="Username"
                  value={userName}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangeUserName}
                  />       

                  <TextField
                  id="outlined-multiline-flexible"
                  label="Password"
                  type="password"
                  value={password}
                  sx={{mt: 2, width: '100%'}}
                  onChange={handleChangePassword}
                  />        
              </CardContent>
              <CardActions>
                  <Button variant="outlined" className="btnPrimary login" onClick={login}>Masuk</Button>
              </CardActions>
          </Card>    

      }          

    </div>
  );
}
