import React from "react";
import { useTheme, useMediaQuery } from '@material-ui/core';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function Footer(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  
  const sendWA = () => {
    let text = "Halo,\n\n"+
    "Saya ingin berdiskusi lebih lanjut.";

    window.open('https://api.whatsapp.com/send?phone='+props.info_whatsapp+'&text='+encodeURIComponent(text), '_blank', 'noopener,noreferrer'); 
}


  return (
    <Box sx={{ width: '100%' }} className='footerSection'>

      <div className="sectionOverlayInsideFooter">
        <div className="sectionOverlayOutsideFooter containerVC">
            <Container>
                <Grid container 
                spacing={mobile ? 4 : 10} px={mobile ? 2 : 0} py={4}>
                    <Grid item xs={12} md={7}>
                        <img src={require('../images/logo.png')} width={mobile ? 110 : 160} alt="DAKA" />
                        <Typography variant={mobile ? "body2" : "body1"} mt={4} className="justify-text font-thin" >
                        {props.info_nama_perusahaan} {props.info_deskripsi_singkat}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Typography variant={mobile ? "h5" : "h4"} gutterBottom className="font-weight" >Hubungi Kami</Typography>
                        <Divider />
                        <Typography variant={mobile ? "body2" : "body1"} gutterBottom className='justify-text font-thin' >
                          Alamat : {props.info_alamat}
                        </Typography>
                        <Typography variant={mobile ? "body2" : "body1"} gutterBottom className='justify-text font-thin' >
                          Head Office : {props.info_head_office}
                        </Typography>
                        <Typography variant={mobile ? "body2" : "body1"} gutterBottom className='justify-text font-thin' >
                          Telp : {props.info_telp}
                        </Typography>
                        <Typography variant={mobile ? "body2" : "body1"} gutterBottom className='justify-text font-thin' >
                          Email : {props.info_email}
                        </Typography>                                            
                    </Grid>

                </Grid>
            </Container>
        </div>
      </div>

      <IconButton aria-label="wa" className="waSection" onClick={sendWA}>
        <img src={require('../images/wa.png')} width={mobile ? 48 : 64} alt="WhatsApp" />
      </IconButton>
    </Box>
  );
}
