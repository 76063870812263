import React from "react";
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { slice } from 'lodash';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Popover from '@mui/material/Popover';
import { NavLink } from "react-router-dom";
import GlobalVar from "../GlobalVar";

import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function Layanan(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [idL, setIdL] = React.useState(null);
  const [desc, setDesc] = React.useState(null);

  const [openLoading, setOpenLoading] = React.useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };
  const handleToggleLoading = () => {
    setOpenLoading(!openLoading);
  };

  const handleClick = (event, id, desc) => {
    setAnchorEl(event.currentTarget);
    setIdL(id);
    setDesc(desc);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIdL(null);
    setDesc(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tablet = useMediaQuery(theme.breakpoints.down('sm')); 
  // ====================== GET LAYANAN ======================
    const [arrayLayanan, setArrayLayanan] = React.useState([]);
  // ====================== END - GET LAYANAN ======================

  React.useEffect(() => {  
    handleToggleLoading(); 
    axios.get(GlobalVar.server+'GetLayanan.php').then(function(response){

      let res = JSON.stringify(response.data);
      let resP = JSON.parse(res);
      setArrayLayanan(resP);
      setListItem(response.data);
      handleCloseLoading();  

    });
    
  }, []);

  const [listItem, setListItem] = React.useState([]);
  const [index, setIndex] = React.useState(5);
  const initialPosts = slice(listItem, 0, index);
  const [isMore, setIsMore] = React.useState(true)
  
  const loadMoreLess = () => {
      if (index >= listItem.length) {
          setIndex(5);
      } else {
          setIndex(index + 5);
      }        
  }
  return (
    <div>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openLoading}
      // onClick={handleCloseLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
        <div role="presentation" className="navBreadcumb">
          <div className="titleBreadcumb">
            <Typography variant='h5'>Layanan</Typography>
          </div>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Typography variant='body2'><NavLink to='../'>Beranda</NavLink></Typography>
            <Typography variant='body2'>Layanan</Typography>
          </Breadcrumbs>
        </div>

        <Container style={{marginTop: -80}}>
        {
          listItem.length > 0 &&
            initialPosts.map((item) => (
              <Card sx={{ display: 'flex', my: 4 }} >
            {
              item.layanan_id % 2 !== 0 &&
                <CardMedia
                  component="img"
                  sx={{ width: mobile ? 120 : 480 }}
                  image={`data:image/*;base64,${item.layanan_foto}`}
                  alt={item.layanan_judul}
                />
            }
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent className="containerVC">
                  <Typography component="div" variant={mobile ? "body1" : "h5"}>
                  {item.layanan_judul}
                  </Typography>
                  <Typography aria-describedby={id} variant={mobile ? "body2" : "body1"} className="font-thin ">
                    {mobile ? item.layanan_deskripsi.substring(0, 50) : item.layanan_deskripsi.substring(0, 85)}
                    <IconButton aria-label="..." onClick={(e) => handleClick(e, item.layanan_id, item.layanan_deskripsi)}>
                      <MoreHorizIcon sx={{fontSize: 15, color: '#333'}} />
                    </IconButton>
                  </Typography> 
                </CardContent>
              </Box>
              {
                item.layanan_id % 2 === 0 &&
                  <CardMedia
                    component="img"
                    sx={{ width: mobile ? 120 : 480 }}
                    image={`data:image/*;base64,${item.layanan_foto}`}
                    alt={item.layanan_judul}
                  />
              }
            </Card>
              
            ))
      }
        <br></br>
        <center>
        {   
            index >= listItem.length ? 
                listItem.length > 5 &&
                (
                    <Button disableElevation startIcon={<ExpandLessIcon />} size="medium" style={{marginTop: 10, marginBottom: 40, textDecoration: 'underline'}} 
                    onClick={loadMoreLess} className="btnPrimary"> Lebih Sedikit</Button>
                ) 
                
            : 
                listItem.length > 5 &&
            (
                <Button disableElevation startIcon={<ExpandMoreIcon />} size="medium" style={{marginTop: 10, marginBottom: 40, textDecoration: 'underline'}} 
                onClick={loadMoreLess} className="btnPrimary" > Lebih Banyak</Button>                                  
            )
        }

        </center>        
                  
          <Popover
            id={idL}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Container>
              <Typography sx={{ mt: 6 }} variant={mobile ? "h6" : "h5"} >Deksripsi :</Typography>
              <Typography sx={{ mt: 2 }} variant={mobile ? "body2" : "body1"} style={{maxHeight: '80%', overflowY: 'scroll'}}>{desc}</Typography>

            </Container>
          </Popover>
        
        </Container>
    </div>
  );
}
