import React from "react";
import axios from 'axios';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { slice } from 'lodash';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Popover from '@mui/material/Popover';
import { NavLink } from "react-router-dom";
import GlobalVar from "../GlobalVar";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function Proyek(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [idL, setIdL] = React.useState(null);
  const [desc, setDesc] = React.useState(null);
  const [image1, setImage1] = React.useState(null);
  const [image2, setImage2] = React.useState(null);
  const [image3, setImage3] = React.useState(null);
  const [image4, setImage4] = React.useState(null);
  const [project_judul, setProject_judul] = React.useState(null);
  const [project_owner, setProject_owner] = React.useState(null);
  const [project_lokasi, setProject_lokasi] = React.useState(null);
  const [project_tahun, setProject_tahun] = React.useState(null);

  const [openLoading, setOpenLoading] = React.useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };
  const handleToggleLoading = () => {
    setOpenLoading(!openLoading);
  };

  const handleClick = (event, id, desc,img1,img2,img3,img4,judul,owner,lokasi,tahun) => {
    setAnchorEl(event.currentTarget);
    setIdL(id);
    setDesc(desc);
    setImage1(img1);
    setImage2(img2);
    setImage3(img3);
    setImage4(img4);
    setProject_judul(judul);
    setProject_owner(owner);
    setProject_lokasi(lokasi);
    setProject_tahun(tahun);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIdL(null);
    setDesc(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const tablet = useMediaQuery(theme.breakpoints.down('sm')); 

  // ====================== GET PROYEK ======================
  const [arrayProyek, setArrayProyek] = React.useState([]);
  // ====================== END - GET PROYEK ======================


React.useEffect(() => {    
  handleToggleLoading();
  axios.get(GlobalVar.server+'GetProyek.php').then(function(response){
    let res = JSON.stringify(response.data);
    let resP = JSON.parse(res);
    setArrayProyek(resP);
    setListItem(response.data);
    handleCloseLoading();  
  });
  
  
}, []);

const [listItem, setListItem] = React.useState([]);
const [index, setIndex] = React.useState(4);
const initialPosts = slice(listItem, 0, index);
const [isMore, setIsMore] = React.useState(true)

const loadMoreLess = () => {
    if (index >= listItem.length) {
        setIndex(4);
    } else {
        setIndex(index + 4);
    }        
}
  return (
    <div>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openLoading}
      // onClick={handleCloseLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    
      <div role="presentation" onClick={handleClick} className="navBreadcumb">
        <div className="titleBreadcumb">
          <Typography variant='h5'>Proyek</Typography>
        </div>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Typography variant='body2'><NavLink to='../'>Beranda</NavLink></Typography>
          <Typography variant='body2'>Proyek</Typography>
        </Breadcrumbs>
      </div>

      <Container style={{marginTop: -80}}>
        <Grid container spacing={mobile ? 2 : 4} px={mobile ? 2 : 0} mt={mobile ? 2 : 0} mb={4}>
        {
          listItem.length > 0 &&
            initialPosts.map((item) => (
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="240"
                      image={`data:image/*;base64,${item.project_gambar}`}
                      alt={item.project_judul}
                    />
                    <CardContent>
                      <Typography variant={mobile ? "h6" : "h5"} className='aLink' component="a" gutterBottom onClick={(e) => handleClick(e, item.project_id, item.project_deskripsi, item.project_gambar,
                          item.project_gambar2,item.project_gambar3,item.project_gambar4,item.project_judul, item.project_owner, item.project_lokasi, item.project_tahun)}>
                      {item.project_judul}
                      </Typography>
                      <Divider />
                      <Typography variant={mobile ? "body1" : "h6"} mt={2}>
                      Pemilik : <span className="font-thin">{item.project_owner}</span>
                      </Typography>
                      <Typography variant={mobile ? "body1" : "h6"} mt={1}>
                      Lokasi : <span className="font-thin">{item.project_lokasi}</span>
                      </Typography>
                      <Typography variant={mobile ? "body1" : "h6"} mt={1}>
                      Tahun : <span className="font-thin">{item.project_tahun}</span>
                      </Typography>
                      <Typography variant={mobile ? "body1" : "h6"} className="font-thin" mt={2}>
                        
                      </Typography>
                      <Typography aria-describedby={id} variant={mobile ? "body1" : "h6"}>{item.project_deskripsi.substring(0, 70)}
                        <IconButton aria-label="..." onClick={(e) => handleClick(e, item.project_id, item.project_deskripsi, item.project_gambar,
                          item.project_gambar2,item.project_gambar3,item.project_gambar4,item.project_judul, item.project_owner, item.project_lokasi, item.project_tahun)}>
                          <MoreHorizIcon sx={{fontSize: 15, color: '#333'}} />
                        </IconButton>
                      </Typography> 
                    </CardContent>
                  </Card>
                </Grid>
              
            ))
      }
          <br></br>
          <Grid item xs={12}>
            <center>
            {   
                index >= listItem.length ? 
                    listItem.length > 4 &&
                    (
                        <Button disableElevation startIcon={<ExpandLessIcon />} size="medium" style={{marginTop: 10, marginBottom: 40, textDecoration: 'underline'}} 
                        onClick={loadMoreLess} className="btnPrimary"> Lebih Sedikit</Button>
                    ) 
                    
                : 
                    listItem.length > 4 &&
                (
                    <Button disableElevation startIcon={<ExpandMoreIcon />} size="medium" style={{marginTop: 10, marginBottom: 40, textDecoration: 'underline'}} 
                    onClick={loadMoreLess} className="btnPrimary" > Lebih Banyak</Button>                                  
                )
            }
            </center>          
          </Grid> 
        </Grid>
                  
          <Popover
            id={idL}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

              <Container>

                <Grid container mt={6} mb={4}>
                  
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={false} responsive={[{
                            breakpoint: 800,
                            settings: {
                              slidesToShow: 3,
                              slidesToScroll: 3
                            }
                          }, {
                            breakpoint: 500,
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2
                            }
                          }]}>
                              <div className="each-slide-effect">
                                  <div style={{ 'backgroundImage': `url(data:image/*;base64,${image1})` }}></div>
                              </div>
                                <div className="each-slide-effect">
                                    <div style={{ 'backgroundImage': `url(data:image/*;base64,${image2 != '' ? image2 : image1})` }}></div>
                                </div>
                                <div className="each-slide-effect">
                                    <div style={{ 'backgroundImage': `url(data:image/*;base64,${image3 != '' ? image3 : image1})` }}></div>
                                </div>
                                <div className="each-slide-effect">
                                    <div style={{ 'backgroundImage': `url(data:image/*;base64,${image4 != '' ? image4 : image1})` }}></div>
                                </div>
                          </Slide>
                          
                          <Typography variant={mobile ? "h6" : "h5"} mt={2} component="div" gutterBottom>
                          {project_judul}
                          </Typography>
                          <Divider />
                          <Typography variant={mobile ? "body1" : "h6"} mt={2}>
                          Pemilik : <span className="font-thin">{project_owner}</span>
                          </Typography>
                          <Typography variant={mobile ? "body1" : "h6"} mt={1}>
                          Lokasi : <span className="font-thin">{project_lokasi}</span>
                          </Typography>
                          <Typography variant={mobile ? "body1" : "h6"} mt={1}>
                          Tahun : <span className="font-thin">{project_tahun}</span>
                          </Typography>
                          <Typography variant={mobile ? "body1" : "h6"} mt={2} className='desc'>{desc}</Typography> 
                        </CardContent>
                      </Card>
                    </Grid>
                  
              </Grid>
            
            </Container>
          </Popover>
      </Container>   

    </div>

  );
}
